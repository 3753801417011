<template>
  <div class="footer_container">
    <div class="top">
      <div v-for="item in data1"
           :key="item.name"
           @click="goto(item.name,item.path)">
        <div style="cursor:pointer">{{item.name}}</div>
        <div style="color:rgba(255,255,255,0.7);margin-top:15px"
             v-for="i in item.children"
             :key="i.name">{{i.name}}</div>
      </div>
    </div>
    <div class="bottom">
      Copyright © 2022 广东海鸥飞行汽车集团有限公司 <span style="margin-left:10px;cursor:pointer"
            @click="toBeian">粤ICP备2022101416号</span>
      <div style="display:flex;align-items:center">
        <a target="_blank"
           href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44049102496902"
           style="display:flex;text-decoration:none;margin-left:15px">
          <img style="width:20px;height:20px"
               :src="`${$staticUrl}/beian.png`" />
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">粤公网安备 44049102496902号</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      data1: [
        {
          name: '首页',
          path: '/',
          children: [
            { name: '' }
          ]
        },
        {
          name: '新闻中心',
          path: '/news',
          children: [
            { name: '公司新闻' },
            { name: '产品新闻' },
          ]
        },
        {
          name: '产品展示',
          path: '/products',
          children: [
            { name: '飞行汽车' },
            { name: '航电系统' },
            { name: '飞行监控指挥系统' },
            { name: '航空发动机' },
          ]
        },
        {
          name: '关于我们',
          path: '/about',
          children: [
            { name: '公司介绍' },
            { name: '团队介绍' },
            { name: '企业文化' },
            { name: '联系我们' },
          ]
        },
        // {
        //   name: '合作伙伴',
        //   path: '/partners',
        //   children: [
        //     { name: '国际合作伙伴' },
        //     { name: '国内合作伙伴' }
        //   ]
        // },
      ]
    }
  },
  methods: {
    goto (name, path) {
      sessionStorage.setItem('CM', name)
      this.$router.push({ path: path })
      this.$emit('changeMenu', name)
    },
    toBeian () {
      // 新建标签页打开
      window.open("https://beian.miit.gov.cn/")
      // 当前页打开
      // window.location.href="https://beian.miit.gov.cn/"; 
    }
  },
  mounted () {

  },
}
</script>
<style lang='less' scoped>
.footer_container {
  width: 100%;
  height: 380px;
  overflow: hidden;
  background: rgb(28, 28, 28);
  padding: 0 390px;
  overflow: hidden;
}
.top {
  display: flex;
  justify-content: space-around;
  height: 80%;
  color: #ffffff;
  font-size: 16px;
  padding: 40px 0;
}
.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff80;
  font-size: 14px;
  height: 20%;
  line-height: 60px;
  text-align: center;
  border-top: 1px solid #eeeeee80;
}
</style>