<template>
  <Frame :width="1920" :height="970" bgColor="#fff">
    <div class="container" id="container">
      <TheHeader ref="header"></TheHeader>
      <div class="content">
          <router-view />
      </div>
      <TheFooter @changeMenu="changeMenu"></TheFooter>
    </div>
  </Frame>
</template>

<script>
import Frame from './Frame'
import TheHeader from './TheHeader.vue'
import TheFooter from './TheFooter.vue'
export default {
  components:{
    Frame,
    TheHeader,
    TheFooter,
  },
  data() {
    return {
      cMenu:''
    }
  },
  methods: {
    changeMenu(name){
      this.$refs.header.cItem=name
    }
  },
  mounted() {

  },
}
</script>
<style lang='less' scoped>
.container{
  width:100%;
  height: 100%;
}
.content{
  margin-top:73px;
}
</style>