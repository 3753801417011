<template>
  <div class="header_container"
       id="header">
    <div class="part1">
      <div>
        <img style="width:56px; margin-right: 20px" :src="`${$staticUrl}/themeIcon.png`" />
        <div class="company">广东海鸥飞行汽车集团有限公司</div>
      </div>
    </div>
    <div class="part2">
      <div v-for="item in menu"
           :key="item.name">
        <router-link :to="item.path">
          <div @click="setcItem(item.name)"
               :class="['menu_item',item.name==cItem?'actived':'']">
            {{item.name}}
          </div>
        </router-link>
      </div>
    </div>
    <div class="part3"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      cItem: sessionStorage.getItem('CM') || '首页',
      menu: [
        {
          name: '首页',
          path: '/',
        },
        {
          name: '新闻中心',
          path: '/news',
        },
        {
          name: '产品展示',
          path: '/products',
        },
        {
          name: '关于我们',
          path: '/about',
        },
        // {
        //   name: '合作伙伴',
        //   path: '/partners',
        // },
      ],
    }
  },
  methods: {
    setcItem (item) {
      this.cItem = item
      sessionStorage.setItem('CM', item)
    }
  },
  mounted () {

  },
}
</script>
<style lang="less" scoped>
.header_container {
  height: 74px;
  width: 100%;
  background: #1e1e1e;
  display: flex;
  font-size: 20px;
  position: fixed;
  top: 0;
  z-index: 99;
}
.part1 {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.part2 {
  flex: 3;
  display: flex;
  justify-content: center;
  .menu_item {
    cursor: pointer;
    width: 136px;
    height: 100%;
    text-align: center;
    line-height: 74px;
    color: rgba(255, 255, 255, 0.8);
    &:hover {
      color: #fff;
    }
  }
  .actived {
    color: #fff;
    font-weight: bold;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.6);
  }
}
.part3 {
  flex: 1;
}
.company {
  color: #fff
}
</style>