import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Button, message, Pagination, Empty, Skeleton } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import 'animate.css';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import {staticUrl} from './api/config'




//引入样式
// import 'swiper/css/swiper.css'
 
Vue.use(VueAwesomeSwiper, /* { 全局组件的默认选项 } */)
Vue.use(Button);
Vue.use(Pagination);
Vue.use(Empty);
Vue.use(Skeleton);

Vue.config.productionTip = false
Vue.prototype.$staticUrl = staticUrl
Vue.prototype.$message = message;
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
