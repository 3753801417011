<template>
  <div>
    <div>
      <video id="video"
             width="100%"
             muted
             autoplay="autoplay"
             loop="loop"
             controls>
        <source :src="`${$staticUrl}/homevideo.mp4`"
                type="video/mp4">
      </video>
    </div>

    <div style="margin-top:-7px">
      <img :src="`${$staticUrl}/home2.png`" />
    </div>
    <div style="position:relative">
      <img :src="`${$staticUrl}/BG.png`" />
      <div class="img_list1">
        <div class="img_list1_item"
             @click="goto(item.label)"
             v-for="item in data1"
             :key="item.label">
          <img :src="item.src" />
        </div>
      </div>
    </div>
    <div style="position:relative;overflow:hidden;">
      <img :src="`${$staticUrl}/home3.png`" />
      <div class="img_list2">
        <img :src="`${$staticUrl}/our_goal.png`" />
      </div>
    </div>
    <div class="yingyong">
      <div class="img_list3">
        <div class="img_list3_item"
             style="position:relative"
             v-for="item in data2"
             :key="item.label">
          <img :src="item.src" />
          <!-- <div class="yingyong_inner_img">
            <div>{{item.text}}</div>
            <div>{{item.textEn}}</div>
          </div> -->
        </div>
      </div>
      <div class="yingyong_title">
        <img :src="`${$staticUrl}/home13.png`" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: {},
  data () {
    return {
      videoHeight: 700,
      data1: [
        {
          label: '1',
          src: `${this.$staticUrl}/home-production-1.png`
        },
        {
          label: '2',
          src: `${this.$staticUrl}/home-production-2.png`
        },
        // {
        //   label: '3',
        //   src: `${this.$staticUrl}/home-production-3.png`
        // },
        {
          label: '3',
          src: `${this.$staticUrl}/home-production-4.png`,
        },
        {
          label: '4',
          src: `${this.$staticUrl}/home-production-5.png`,
        },
      ],
      data2: [
        {
          label: '1',
          src: `${this.$staticUrl}/home-use-1.png`,
          src1: `${this.$staticUrl}/home4-1.png`,
          text: '城际空中交通需求',
          textEn: 'INTERCITY AIR TRAFFIC'
        },
        {
          label: '2',
          src: `${this.$staticUrl}/home-use-2.png`,
          src1: `${this.$staticUrl}/home5-1.png`,
          text: '特种行业需求',
          textEn: 'SPECIAL INDUSTRY'
        },
        {
          label: '3',
          src: `${this.$staticUrl}/home-use-3.png`,
          src1: `${this.$staticUrl}/home6-1.png`,
          text: '未来城市空中交通需求',
          textEn: 'AIR TRAFFIC IN THE FUTURE'
        },
        {
          label: '4',
          src: `${this.$staticUrl}/home-use-4.png`,
          src1: `${this.$staticUrl}/home7-1.png`,
          text: '未来大众个人飞行需求',
          textEn: 'PERSONAL TRAVEL DEMAND'
        },
        {
          label: '5',
          src: `${this.$staticUrl}/home-use-5.png`,
          src1: `${this.$staticUrl}/home7-1.png`,
          text: '未来大众个人飞行需求',
          textEn: 'PERSONAL TRAVEL DEMAND'
        },
      ],
      flag1: true,
      flag2: true,
    }
  },
  methods: {
    isElementNotInViewport (el) {
      if (el) {
        let rect = el.getBoundingClientRect();
        return (
          rect.top >=
          (window.innerHeight || document.documentElement.clientHeight) ||
          rect.bottom <= 0
        );
      }
    },
    goto (val) {
      this.$router.push({
        path: "/pdetails/" + val,
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.videoHeight = window.innerHeight - document.getElementById('header').clientHeight
    })
    const isElementNotInViewport = (el) => {
      if (el) {
        let rect = el.getBoundingClientRect();
        return (
          rect.top >=
          (window.innerHeight || document.documentElement.clientHeight) ||
          rect.bottom <= 0
        );
      }
    }
    let that = this
    let dom1 = document.getElementsByClassName('img_list1')[0]
    let dom2 = document.getElementsByClassName('img_list2')[0]
    window.addEventListener('scroll', () => {
      if (isElementNotInViewport(dom1) == false) {
        if (that.flag1) {
          let doms = document.getElementsByClassName('img_list1_item')
          for (let el of doms) {
            el.className += ' animate__animated animate__slideInUp animate__delay'
          }
          that.flag1 = false
        }
      }
      if (isElementNotInViewport(dom2) == false) {
        if (that.flag2) {
          dom2.className += ' animate__animated animate__slideInUp animate__delay'
          that.flag2 = false
        }
      }
    }, true)
  },
}
</script>
<style lang='less' scoped>
#video {
  // object-fit: cover;
  height: 900px;
  background: black;
  overflow: hidden;
}
.img_list1 {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  transform: translate(-50%, -40%);
  gap: 25px;
  padding: 20px 200px;
}
.img_list2 {
  position: absolute;
  right: 220px;
  bottom: 130px;
}
.yingyong {
  position: relative;
  .img_list3 {
    display: flex;
    position: relative;
    .img_list3_item {
      overflow: hidden;
      cursor: pointer;
      &:hover {
        filter: brightness(1.5);
        transition: 0.6s;
      }
    }
    .yingyong_inner_img {
      position: absolute;
      width: 100%;
      color: rgba(255, 255, 255, 0.7);
      top: 50%;
      left: 50%;
      text-align: center;
      line-height: 40px;
      letter-spacing: 5px;
      font-size: 22px;
      transform: translate(-50%, -50%);
    }
  }
  .yingyong_title {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.img_list1_item {
  cursor: pointer;
  display: inline-block;
  border-radius: 10px;
  overflow: hidden;
}
.img_list1_item img:hover {
  transform: scale(1.1);
  transition: all 0.8s ease-in-out;
}
</style>