import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Layout from '@/components/Layout'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect: '/home',
    children:[
      {
        path:'/home',
        name:'Home',
        component:()=>import(/* webpackChunkName: "home" */ '../views/Home.vue')
      },
      {
        path:'/partners',
        name:'Partners',
        component:()=>import(/* webpackChunkName: "partners" */ '../views/Partners.vue')
      },
      {
        path:'/about',
        name:'About',
        component:()=>import(/* webpackChunkName: "about" */ '../views/About/index.vue')
      },
      {
        path:'/products',
        name:'Products',
        component:()=>import(/* webpackChunkName: "products" */ '../views/Products/Products.vue'),
      },
      {
        path:'/news',
        name:'News',
        component:()=>import(/* webpackChunkName: "news" */ '../views/News/News.vue'),
      },
      {
        path:'pdetails/:id',
        name:'PDetails',
        component:()=>import(/* webpackChunkName: "products" */ '../views/Products/details.vue')
      },
      {
        path:'pdetails2/:id',
        name:'PDetails2',
        component:()=>import(/* webpackChunkName: "products" */ '../views/Products/details2.vue')
      },
      {
        path:'ndetails/:id',
        name:'NDetails',
        component:()=>import(/* webpackChunkName: "products" */ '../views/News/details.vue')
      }
    ]
  },
]

const router = new VueRouter({
  routes,
})
// 路由跳转后页面滚动到顶部
router.beforeEach((to, from, next) => {
  next()
})
router.afterEach(() => {
  try{
    document.getElementById('container').scrollTop = 0
  }catch(e){

  }
})

export default router
